.contentManagement {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    div.addButtonContainer {
        display: flex;
        justify-content: right;

        button.addNew {
            cursor: pointer;
            padding: 15px 50px;
            border-radius: 15px;
            background-color: rgba(10, 116, 198, 0.993);

            span {
                margin-right: 10px;
                font-size: 15px;
            }
        }
    }


    div.contentContainer {
        padding: 20px 0;

        .emptyContainer {
            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 100px 0;
            text-align: center;

            h1 {
                color: grey;
            }
        }


        .card {
            div.cardContent {
                cursor: pointer;
                padding: 20px;
                border-radius: 10px;
                background-color: #F8F8F8;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                h1.title {
                    margin: 0;
                    margin-bottom: 2px;
                    font-size: 22px;
                    color: #244151;
                }

                p.name_date {
                    font-size: 12px;
                    color: #244151;
                    margin: 0;

                    span {
                        display: inline-block;
                    }
                }

                p.description {
                    margin: 11px 0;
                    font-size: 14px;
                    color: #506774;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;

                    p {
                        margin: 0;
                        margin: 11px 0;
                        font-size: 14px;
                        color: #506774;
                    }
                }
            }
        }
    }
}