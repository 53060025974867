.addOrUpdateBlog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .backContainer {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: gray;


        span.icon svg {
            font-size: 1.5rem;
        }

        h3.backText {
            margin: 0 0 7.5px 5px;
            font-size: 17px;
            color: gray;
        }
    }

    div.contentContainer {
        margin: 15px 0;
        padding: 25px 30px;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        h1.title {
            font-size: 24px;
            font-weight: 600;
        }

    }
}