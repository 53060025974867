.chatContainer {
    width: 100%;
    height: inherit;


    .loadingContainer {
        width: 100%;
        height: calc(100% - 35px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.chats {
        padding: 10px 20px 0;
        height: calc(100% - 35px);
        overflow: auto;
        display: flex;
        flex-direction: column-reverse;

        @media (min-width : 1400px) {
            width: 50%;
            margin: auto;
        }

        div.chat {
            max-width: 50%;
            width: fit-content;
            padding: 5px 10px;
            margin: 10px 0;
            border-radius: 8px;

            p.text {
                margin: 0;
                word-break: break-all;
            }

            div.mediaContainer {
                width: 100%;
                aspect-ratio: 16/9;
                margin: 5px 0;

                img,
                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }

            }

            &.me {
                background-color: #469CDA;
                margin-left: auto;
            }

            &.other {
                background-color: lightgrey;
                margin-right: auto;

                p.text {
                    color: black
                }
            }


        }
    }

    .filebackground {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        // background-color: rgba(126, 121, 121, 0.418);
        z-index: 10;
        top: 0;
        left: 0;
    }

    div.chatInputContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 35px;


        .fileContainer {
            width: 40%;
            padding: 30px;
            background-color: #e6e7e9;
            position: absolute;
            bottom: 35px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: black;
            z-index: 11;

            @media (max-width : 1400px) {
                width: 70%;
            }

            .preview {
                max-width: 50%;
            }

            button.removeFile {
                position: absolute;
                top: 10px;
                right: 10px;
                border-radius: 100%;
                display: flex;
                width: fit-content;
                height: fit-content;
                justify-content: center;
                align-items: center;

                .cancelIcon {
                    font-size: 20px;
                }
            }

            p.filename {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-align: center;
            }
        }

        .fieldContainer {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            z-index: 11;
            position: absolute;
            width: 100%;

            button.send {
                width: 35px;
                height: 35px;
                margin-left: 10px;
                background-color: #469CDA;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    font-size: 18px;
                }
            }

            button.attachment {
                width: 35px;
                height: 35px;
                margin-left: 10px;
                background-color: #cecfcf;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                .attachment {
                    font-size: 18px;
                    color: black;

                }

            }
        }
    }
}