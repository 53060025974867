.donate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;


    div.contentContainer {
        margin: 15px 0;
        padding: 25px 35px;
        width: 100%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        h1.title {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }

        div.fieldsContainer {
            margin: 5px 0;

            div.fields {
                width: 300px;
                margin: 30px 0;

                p.label {
                    color: grey;
                    margin: 0;
                    margin-bottom: 8px;
                    font-weight: 450;
                }

                input {
                    width: 100%;
                    padding: 7px 10px;
                    border-radius: 8px;
                    border: 1px solid grey;
                    color: black;
                    font-weight: 400;
                    font-size: 15px;

                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        button.save {
            cursor: pointer;
            margin-top: 20px;
            padding: 15px 60px;
            border-radius: 15px;
            background-color: rgba(10, 116, 198, 0.993);
            width: fit-content;

        }

    }
}