.acc-class-code {
    display: inline-block !important;
    width: fit-content !important;
    margin: 20px 4px !important;
    background-color: #F8F8F8;
    color: black;
    text-align: left;
    padding: 7px 50px !important;
}

#editor a,
#editor ul {
    color: black;
    font-size: 17px;
}