.cardContainer {
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .userManagement {
        height: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        overflow-y: overlay;


        .contentTitle {
            margin-bottom: 40px;
            color: white;
        }
    }
}