.htmlContainer p {
    color: black;
}


.htmlContainer .fr-strong {
    font-weight: 600;
    text-decoration: none;
}

.htmlContainer .ls-class-code0,
.htmlContainer .ls-class-code1,
.htmlContainer .ls-class-code2,
.htmlContainer .ls-class-code3,
.htmlContainer .acc-class-code {
    display: block;
    width: 100%;
    margin: 20px 0;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    padding: 10px 40px !important;
    border: none !important;
}

.htmlContainer .acc-class-code,
.htmlContainer .acc-class-code:hover {
    text-align: left;
    background-color: #F8F8F8;
    text-decoration: none;
    padding: 10px 40px !important;
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.htmlContainer .acc-class-code:after {
    display: inline-block;
    -webkit-transform: scale(0.5, 1);
    /* Safari and Chrome */
    -moz-transform: scale(0.5, 1);
    /* Firefox */
    -ms-transform: scale(0.5, 1);
    /* IE 9 */
    -o-transform: scale(0.5, 1);
    /* Opera */
    transform: scale(0.5, 1);
    /* W3C */
    content: ">";
    position: absolute;
    margin-left: 10px;
}

.htmlContainer .ls-class-code0,
.htmlContainer .ls-class-code0:hover {
    background-color: #db8f473e;
    color: #D77A25;
    text-decoration: none;
}

.htmlContainer .ls-class-code1,
.htmlContainer .ls-class-code1:hover {
    background-color: #d37e7e3c;
    color: #D37E7E;
    text-decoration: none;
}

.htmlContainer .ls-class-code2,
.htmlContainer .ls-class-code2:hover {
    background-color: #8d79c148;
    color: #8D79C1;
    text-decoration: none;
}

.htmlContainer .ls-class-code3,
.htmlContainer .ls-class-code3:hover {
    background-color: #469cda3c;
    color: #469CDA;
    text-decoration: none;
}

.htmlContainer .ls-class-code4,
.htmlContainer.ls-class-code4:hover {
    background-color: #4d849639;
    color: #4D8496;
    text-decoration: none;
}

.htmlContainer .ls-class-code5,
.htmlContainer .ls-class-code5:hover {
    background-color: #5477be46;
    color: #5477BE;
    text-decoration: none;
}