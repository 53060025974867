.ls-class-code {
    display: inline-block !important;
    width: fit-content !important;
    margin: 20px 4px !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-image: linear-gradient(to right, red, purple, blue) 1 !important;
    border-radius: 20px !important;
    padding: 4px 50px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    text-align: center;
}


.acc-class-code {
    display: inline-block !important;
    width: fit-content !important;
    margin: 20px 4px !important;
    background-color: #F8F8F8;
    color: black;
    text-align: left;
    padding: 7px 50px !important;
}