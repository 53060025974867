.cardContainer {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    div.addButtonContainer {
        display: flex;
        justify-content: right;

        button.addNew {
            cursor: pointer;
            padding: 15px 50px;
            border-radius: 15px;
            background-color: rgba(10, 116, 198, 0.993);

            span {
                margin-right: 10px;
                font-size: 15px;
            }
        }
    }

    div.contentContainer {
        padding: 20px 0;

    }
}