.viewBlog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    div.headerContainer {
        display: flex;
        flex-direction: row;

        .backContainer {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: gray;


            span.icon svg {
                font-size: 1.5rem;
            }

            h3.backText {
                margin: 0 0 7.5px 5px;
                font-size: 17px;
                color: gray;
            }
        }

        .editButtonContainer {
            margin-left: auto;

            .buttons {
                cursor: pointer;
                padding: 10px 50px;
                border-radius: 15px;
                margin-left: 15px;

                &.delete {
                    background-color: #D87472;
                }

                &.edit {
                    background-color: rgb(58, 168, 58);
                }

                &.activated {
                    background-color: #469cda;
                }

                &.deactivated {
                    background-color: gray;
                }
            }
        }

    }

    div.contentContainer {
        margin: 15px 0;
        padding: 25px 30px;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        h1.title {
            margin: 0;
            margin-bottom: 2px;
            font-size: 26px;
            color: #244151;
        }

        p.name_date {
            font-size: 14px;
            color: #244151;
            margin: 0;

            span {
                display: inline-block;
            }
        }

        p.description {
            margin: 11px 0;
            font-size: 17px;
            color: #506774;

            p {
                margin: 0;
                margin: 11px 0;
                font-size: 17px;
                color: #506774;
            }
        }
    }
}