.dashBoard {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    .contentTitle {
        margin-bottom: 40px;
        color: white;

    }

    .content {
        overflow: overlay;
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        .row {

            @media(max-width: 1199px) {
                justify-content: center;

            }

            .card {
                border-radius: 10px;
                padding: 30px;
                height: 100%;

                @media (max-height : 830px) {
                    padding: 20px;
                }

                .imageBack {
                    background-color: white;
                    display: inline-block;
                    padding: 20px;
                    border-radius: 10px;
                    justify-content: center;
                    align-items: center;

                    img {
                        @media (max-height : 830px) {
                            width: 30px;
                            height: 30px;
                        }

                    }

                }

                h1 {
                    margin: 30px 0 0;
                    font-size: 3rem;
                    font-weight: 700;
                    color: white;

                    @media (max-height : 830px) {
                        font-size: 2rem;
                    }



                }

                h4 {
                    margin: 0;
                    color: white;
                    font-size: 1.1rem;
                    font-weight: 400;

                    @media (max-height : 830px) {
                        font-size: 0.9rem;
                    }

                }

            }
        }
    }

}