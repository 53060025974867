.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    div.headerContainer {
        display: flex;
        flex-direction: row;


        .editButtonContainer {
            margin-left: auto;

            .buttons {
                cursor: pointer;
                padding: 10px 50px;
                border-radius: 15px;
                margin-left: 15px;

                &.edit {
                    background-color: rgb(58, 168, 58);
                }
            }
        }

    }

    div.contentContainer {
        margin: 15px 0;
        padding: 25px 30px;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        color: black;

        ul {
            font-size: 17px;
        }
    }
}