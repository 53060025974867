.cardContainer {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    div.addButtonContainer {
        display: flex;
        justify-content: right;

        button.addNew {
            cursor: pointer;
            padding: 15px 50px;
            border-radius: 15px;
            background-color: rgba(10, 116, 198, 0.993);

            span {
                margin-right: 10px;
                font-size: 15px;
            }
        }
    }

    div.contentContainer {
        padding: 20px 0;


        .emptyContainer {
            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 100px 0;
            text-align: center;

            h1 {
                color: grey;
            }
        }

        .card {



            div.cardContent {
                cursor: pointer;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                max-width: 250px;

                div.imageContainer {
                    width: 100%;
                    display: flex;
                    min-height: 150px;
                    max-height: 17px;
                    max-width: 250px;

                    img {
                        width: 100%;
                        max-width: 50pt;
                        max-height: 60pt;
                        margin: auto;
                    }
                }

                div.textContainer {
                    padding: 15px 20px;

                    @media (max-width : 1400px) {
                        padding: 15px 15px;
                    }

                    h3.name {
                        font-size: 18px;
                        margin: 0;
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;

                        @media (max-width : 1400px) {
                            font-size: 15px;
                        }
                    }
                }
            }

        }
    }
}