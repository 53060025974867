.protectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;


    aside {
        position: relative;
        background-color: white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 1;
        box-shadow: 13px 0 30px -25px #888;


        .children {
            width: 100%;
            height: inherit;
            display: flex;
            flex-direction: column;
            overflow: overlay;

        }

        .logo {
            width: 100%;
            margin: 25px auto 30px;
            text-align: center;
            padding: 0 50px;

            img {
                height: 100%;
                cursor: pointer;
                width: 100%;
            }
        }

        .menu {
            display: flex;
            flex-direction: column;
            overflow: overlay;
            padding: 0 15px;

            .item {
                margin: 2px 0;
                padding: 18px 25px;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                color: gray;
                font-weight: 600;

                &.active {
                    background-color: #469CDA;
                    border-radius: 15px;
                    color: white;
                }

                p {
                    margin: 0 10px;
                }
            }
        }
    }


    .routeContent {
        padding: 0 20px;
        position: relative;
        width: 100%;
        background-color: #FAFBFD;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .content {
            width: 100%;
            height: -webkit-fill-available;
            display: flex;
            overflow: hidden;
            padding: 0 10px;

        }

        .Navbar {
            padding: 25px 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid lightgray;

            .titleContainer {
                margin-right: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                h3.title {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 0;
                }
            }

            .date {
                margin-right: auto;
                display: flex;
                font-size: 1.4rem;
                color: gray;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0 20px;
                    font-size: 1rem;
                }
            }

            .search {
                display: flex;
                flex-direction: row;
                width: 20rem;
                height: auto;
                min-height: 30px;
                padding: 10px 20px;
                background-color: white;
                border-radius: 10px;
                justify-content: center;
                align-items: center;

                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px;
                    color: gray;
                    font-size: 16px;
                    border: none;
                    outline: none;
                }

                .searchButton {
                    width: 35px;
                    height: 30px;
                    border-radius: 100%;
                    background-color: #469CDA;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                    font-size: 1.3rem;
                }


            }

        }
    }



}